import React, {SetStateAction} from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import {NavItemType} from "./NavigationItem";
import {NAVIGATION_DEMO} from "data/navigation";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface NavMobileProps {
    data?: NavItemType[];
    onClickClose?: () => void;
    lang: boolean | true;
    setLang: React.Dispatch<SetStateAction<boolean | true>>
}

const NavMobile: React.FC<NavMobileProps> = ({
                                                 data = NAVIGATION_DEMO,
                                                 onClickClose,
                                                 lang,
                                                 setLang
                                             }) => {
    const onchangeLang = (v: boolean) => {
        if (v) {
            setLang(true);
        } else {
            setLang(false);
        }
    }

    return (
        <div
            className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
            <div className="py-6 px-5">
                <Logo/>
                <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
                    <div className="flex justify-between items-center mt-4">
                        <span className="flex align-content-between justify-between">
                            <button className="px-5" onClick={() => onchangeLang(!lang)}>
                            {
                                lang
                                    ?
                                    "EN"
                                    :
                                    "AR"
                            }
                        </button>
                            <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800"/>
                        </span>
                    </div>
                </div>
                <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose}/>
        </span>
            </div>
        </div>
    );
};

export default NavMobile;
