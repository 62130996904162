import React, {Fragment, FC} from "react";
import {Popover, Transition} from "@headlessui/react";
import {CalendarIcon} from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";

export interface StayDatesRangeInputProps {
    className?: string;
    fieldClassName?: string;
    startDate: Date | null;
    endDate: Date | null;
    onChangeDate: (dates: [Date | null, Date | null]) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
                                                               className = "[ lg:nc-flex-2 ]",
                                                               fieldClassName = "[ nc-hero-field-padding ]",
                                                               startDate,
                                                               endDate,
                                                               onChangeDate
                                                           }) => {
    const excludedDays = () => {
        let currentDate = new Date();
        let enDate = new Date(currentDate.getFullYear(), 11, 31);
        let listDates: any[] = [];
        while (currentDate <= enDate) {
            if (currentDate.getDay() === 5) {
                listDates.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return listDates;
    }

    const renderInput = () => {
        return (
            <>
                <div className="text-neutral-300 dark:text-neutral-400">
                    <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7"/>
                </div>
                <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
            }) || "Pick date"}
          </span>
                    <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {startDate?.toDateString()}
          </span>
                </div>
            </>
        );
    };

    return (
        <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
            {({open}) => (
                <>
                    <Popover.Button
                        className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${
                            open ? "nc-hero-field-focused" : ""
                        }`}
                        onClickCapture={() => document.querySelector("html")?.click()}
                    >
                        {renderInput()}
                        {startDate && open && (
                            <ClearDataButton onClick={() => onChangeDate([null, null])}/>
                        )}
                    </Popover.Button>

                    {open && (
                        <div
                            className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
                    )}

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                            <div
                                className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                                <DatePicker
                                    calendarStartDay={6}
                                    minDate={new Date(+new Date() + 86400000)}
                                    maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                    excludeDates={excludedDays()}
                                    selected={startDate}
                                    onChange={onChangeDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    monthsShown={2}
                                    showPopperArrow={false}
                                    inline
                                    renderCustomHeader={(p) => (
                                        <DatePickerCustomHeaderTwoMonth {...p} />
                                    )}
                                    renderDayContents={(day, date) => (
                                        <DatePickerCustomDay dayOfMonth={day} date={date}/>
                                    )}
                                />
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default StayDatesRangeInput;
