import React, {useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";


const MyRoutes = () => {
    let WIN_WIDTH = useWindowSize().width;
    if (typeof window !== "undefined") {
        WIN_WIDTH = WIN_WIDTH || window.innerWidth;
    }
    const [lang, setLang] = useState(true);

    return (
        <BrowserRouter>
            <ScrollToTop/>
            <SiteHeader lang={lang} setLang={setLang}/>

            <Routes>
                <Route key="/" element={<ListingFlightsPage lang={lang}/>} path="/"/>
                <Route element={<Page404/>}/>
            </Routes>

            {WIN_WIDTH < 768 && <FooterNav lang={lang} setLang={setLang}/>}
            <Footer/>
        </BrowserRouter>
    );
};

export default MyRoutes;
