import React, {FC, SetStateAction} from "react";
import Heading2 from "components/Heading/Heading2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LocationInput from "../../components/HeroSearchForm/LocationInput";

export interface result {
    id: string;
    date: string;
    time: string;
}

export interface pagination {
    count: number;
    next: string | null;
    previous: string | null;
    results: result[];
}

export interface SectionGridFilterCardProps {
    className?: string;
    dateSlot: Date | null;
    timeSlots: pagination | null;
    setTimeSlots: React.Dispatch<SetStateAction<pagination | null>>
    hours: string;
    setHours: React.Dispatch<SetStateAction<string>>;
    minutes: string;
    setMinutes: React.Dispatch<SetStateAction<string>>;
    onSearch: () => void;
    lang: boolean | true;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
                                                                   className = "",
                                                                   dateSlot,
                                                                   timeSlots,
                                                                   setTimeSlots,
                                                                   hours,
                                                                   setHours,
                                                                   minutes,
                                                                   setMinutes,
                                                                   onSearch,
                                                                   lang
                                                               }) => {
    return (
        <div dir={lang ? "ltr" : "rtl"}
             className={`nc-SectionGridFilterCard ${className} mt-5`}
             data-nc-id="SectionGridFilterCard"
        >
            <Heading2
                heading={dateSlot?.toDateString()}
                subHeading={
                    lang
                        ?
                        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                        {
                            timeSlots
                                ?
                                timeSlots.count + " Slots are available"
                                :
                                "No available slots in selected period"
                        }
                            <span className="mx-2">·</span>
                        </span>
                        :
                        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                        {
                            timeSlots
                                ?
                                timeSlots.count + " فترة متاحة"
                                :
                                "لا يوجد بيانات متاحة"
                        }
                            <span className="mx-2">·</span>
                        </span>
                }
            />
            {
                timeSlots
                    ?
                    <>
                        <form dir="ltr"
                              className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 pe-7">
                            <LocationInput placeHolder="Hour/s" desc="Hour/s" slots={["09", "10", "11",
                                "12", "01", "02", "03"]} value={hours} setValue={setHours}/>
                            <span className="flex items-center justify-center p-5 text-xl font-bold">:</span>
                            <LocationInput placeHolder="Minute/s" desc="Minute/s"
                                           slots={["00", "15", "30", "45"]} value={minutes} setValue={setMinutes}/>
                            <span className="flex items-center justify-center p-5 text-xl font-bold">
                                {
                                    ["12", "01", "02", "03"].includes(hours)
                                        ?
                                        "PM"
                                        :
                                        "AM"
                                }
                            </span>
                        </form>
                        <div className="flex items-center justify-center mt-8">
                            <ButtonPrimary onClick={onSearch}>
                                <span className="px-5">
                                    {
                                        lang
                                            ?
                                            "Search"
                                            :
                                            "بحث"
                                    }
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </ButtonPrimary>
                        </div>
                    </>
                    :
                    null
            }
        </div>
    );
};

export default SectionGridFilterCard;
