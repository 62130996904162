"use client";

import React, {FC} from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";

export type SearchTab = "Stays" | "Experiences" | "Cars" | "Flights";

export interface HeroSearchFormProps {
    className?: string;
    currentTab?: SearchTab;
    currentPage?: "Stays" | "Experiences" | "Cars" | "Flights";
    startDate: Date | null;
    endDate: Date | null;
    onChangeDate: (dates: [Date | null, Date | null]) => void;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
                                                     className = "",
                                                     currentTab = "Stays",
                                                     currentPage,
                                                     startDate,
                                                     endDate,
                                                     onChangeDate
                                                 }) => {

    const renderForm = () => {
        return <StaySearchForm startDate={startDate} endDate={endDate} onChangeDate={onChangeDate}/>;
    };

    return (
        <div
            className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
        >
            {renderForm()}
        </div>
    );
};

export default HeroSearchForm;
