import React, {FC} from "react";
import StayDatesRangeInput from "./StayDatesRangeInput";

export interface DataCollectedProps {
    startDate: Date | null;
    endDate: Date | null;
    onChangeDate: (dates: [Date | null, Date | null]) => void;
}

const StaySearchForm: FC<DataCollectedProps> = ({
                                                    startDate,
                                                    endDate,
                                                    onChangeDate
                                                }) => {
    //
    const renderForm = () => {
        return (
            <form
                className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
                <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
                <StayDatesRangeInput className="flex-1" startDate={startDate} endDate={endDate}
                                     onChangeDate={onChangeDate}/>
            </form>
        );
    };

    return renderForm();
};

export default StaySearchForm;
