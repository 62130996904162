import React, {FC, SetStateAction} from "react";
import MainNav1 from "./MainNav1";

export interface HeaderProps {
    navType?: "MainNav1";
    className?: string;
    lang: boolean | true;
    setLang: React.Dispatch<SetStateAction<boolean | true>>
}

const Header: FC<HeaderProps> = ({navType = "MainNav1", className = "", lang, setLang}) => {
    const renderNav = () => {
        switch (navType) {
            case "MainNav1":
                return <MainNav1 lang={lang} setLang={setLang}/>;
            default:
                return <MainNav1 lang={lang} setLang={setLang}/>;
        }
    };

    return (
        <div
            className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg ${className}`}
        >
            {renderNav()}
        </div>
    );
};

export default Header;
