import React, {FC, SetStateAction} from "react";
import Logo from "shared/Logo/Logo";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface MainNav1Props {
    className?: string;
    lang: boolean | true;
    setLang: React.Dispatch<SetStateAction<boolean | true>>
}

const MainNav1: FC<MainNav1Props> = ({className = "", lang, setLang}) => {
    const onchangeLang = (v: boolean) => {
        if (v) {
            setLang(true);
        } else {
            setLang(false);
        }
    }
    return (
        <div className={`nc-MainNav1 relative z-10 ${className}`}>
            <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
                <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
                    <Logo/>
                    {/*<Navigation/>*/}
                </div>

                <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
                    <Logo/>
                </div>

                <div
                    className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
                    <div className="hidden xl:flex items-center space-x-0.5">
                        <button className="px-5" onClick={() => onchangeLang(!lang)}>
                            {
                                lang
                                    ?
                                    "EN"
                                    :
                                    "AR"
                            }
                        </button>
                        <SwitchDarkMode/>
                        <div className="px-1"/>
                    </div>
                    <div className="flex xl:hidden items-center">
                        <button className="px-5" onClick={() => onchangeLang(!lang)}>
                            {
                                lang
                                    ?
                                    "EN"
                                    :
                                    "AR"
                            }
                        </button>
                        <SwitchDarkMode/>
                        <div className="px-0.5"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainNav1;
