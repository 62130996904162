// this sbhsvcdv.
import React from "react";
import MyRouter from "routers/index";
import axios from "axios";

axios.defaults.baseURL = '/';
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

function App() {
    return (
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <MyRouter/>
        </div>
    );
}

export default App;
